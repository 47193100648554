<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0" />
        <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <form
                @submit.prevent="handleSubmit"
                class="
                  h-full
                  divide-y divide-gray-200
                  flex flex-col
                  bg-white
                  shadow-xl
                "
              >
                <div class="flex-1 h-0 overflow-y-auto">
                  <div class="py-6 px-4 bg-light-blue-600 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white">
                        Send new update
                      </DialogTitle>
                      <div class="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          class="
                            bg-light-blue-900
                            rounded-md
                            text-blue-200
                            hover:text-white
                            focus:outline-none
                            focus:ring-2 focus:ring-white
                          "
                          @click="open = false"
                        >
                          <span class="sr-only">Close panel</span>
                          <XIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-white">
                        Fill in the information, add action to send a new update
                        on the case, that will be displayed on client’s
                        dashboard.
                      </p>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col justify-between">
                    <div class="px-4 border-gray-200 border-b sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <fieldset>
                          <legend class="text-sm font-medium text-gray-900">
                            Type
                          </legend>
                          <div class="mt-2 space-y-5">
                            <div
                              :key="type.id"
                              v-for="type in update_types"
                              class="relative flex items-start"
                            >
                              <div class="absolute flex items-center h-5">
                                <input
                                  :value="type.uid"
                                  v-model="update.type"
                                  :id="type.name"
                                  name="type"
                                  :aria-describedby="type.name"
                                  type="radio"
                                  class="
                                    focus:ring-blue-500
                                    h-4
                                    w-4
                                    text-blue-600
                                    border-gray-300
                                  "
                                />
                              </div>
                              <div class="pl-7 text-sm">
                                <label
                                  :for="type.name"
                                  class="font-medium text-gray-900"
                                >
                                  {{ type.name }}
                                </label>
                                <p class="text-gray-500">
                                  {{ type.title }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div class="px-4 border-gray-200 border-b sm:px-6">
                      <div class="my-6 space-y-6 relative flex-1">
                        <div>
                          <label
                            class="
                              block
                              text-sm
                              font-medium
                              text-gray-700
                              sm:mt-px
                              sm:pb-2
                            "
                            >Description</label
                          >
                          <textarea
                            autofocus
                            v-model="update.description"
                            :required="update.type === 'information'"
                            rows="6"
                            class="
                              shadow-sm
                              focus:ring-blue-500
                              focus:border-blue-500
                              block
                              w-full
                              sm:text-sm
                              border border-gray-300
                              rounded-md
                            "
                          ></textarea>
                        </div>
                        <RadioGroup
                          v-if="update.type === 'sign'"
                          v-model="update.signature_type"
                        >
                          <RadioGroupLabel class="sr-only">
                            Signature Types
                          </RadioGroupLabel>
                          <div class="bg-white rounded-md -space-y-px relative">
                            <input
                              :required="update.type === 'sign'"
                              v-model="update.signature_type"
                              class="sr-only right-16 top-6"
                            />
                            <RadioGroupOption
                              as="template"
                              v-for="(signature, i) in signature_types"
                              :key="signature.name"
                              :value="signature.uid"
                              v-slot="{ checked, active }"
                            >
                              <div
                                :class="[
                                  i === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                  i === signature_types.length - 1
                                    ? 'rounded-bl-md rounded-br-md'
                                    : '',
                                  checked
                                    ? 'bg-blue-50 border-blue-200 z-10'
                                    : 'border-gray-200',
                                  'relative border p-4 flex cursor-pointer focus:outline-none',
                                ]"
                              >
                                <span
                                  :class="[
                                    checked
                                      ? 'bg-blue-600 border-transparent'
                                      : 'bg-white border-gray-300',
                                    active
                                      ? 'ring-2 ring-offset-2 ring-blue-500'
                                      : '',
                                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
                                  ]"
                                  aria-hidden="true"
                                >
                                  <span
                                    class="rounded-full bg-white w-1.5 h-1.5"
                                  />
                                </span>
                                <div class="ml-3 flex flex-col">
                                  <RadioGroupLabel
                                    as="span"
                                    :class="[
                                      checked
                                        ? 'text-blue-900'
                                        : 'text-gray-900',
                                      'block text-sm font-medium',
                                    ]"
                                  >
                                    {{ signature.name }}
                                  </RadioGroupLabel>
                                  <RadioGroupDescription
                                    as="span"
                                    :class="[
                                      checked
                                        ? 'text-blue-700'
                                        : 'text-gray-500',
                                      'block text-sm',
                                    ]"
                                  >
                                    {{ signature.title }}
                                  </RadioGroupDescription>
                                </div>
                              </div>
                            </RadioGroupOption>
                          </div>
                        </RadioGroup>
                        <div
                          v-if="
                            update.signature_type === 'digital-signature' &&
                            update.type === 'sign'
                          "
                        >
                          <label
                            for="template-id"
                            class="
                              block
                              text-sm
                              font-medium
                              text-gray-700
                              sm:mt-px
                              sm:pb-2
                            "
                          >
                            Select template or upload new template
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2">
                            <select
                              @change="handleTemplateChange"
                              :required="
                                update.signature_type === 'digital-signature' &&
                                update.type === 'sign'
                              "
                              v-model="update.template_id"
                              id="template-id"
                              name="template_id"
                              class="
                                block
                                focus:ring-blue-500
                                focus:border-blue-500
                                w-full
                                shadow-sm
                                border-gray-300
                                rounded-md
                              "
                            >
                              <option value="new">Upload a new template</option>
                              <option disabled class="text-sm">
                                &nbsp;&nbsp;&nbsp;&uarr; New Template
                              </option>
                              <template
                                :v-for="template in templates"
                              >
                                <option
                                :key="template.template_id" :value="template.template_id">
                                  {{ template.title }}
                                </option>
                              </template>
                            </select>
                          </div>
                        </div>
                        <template v-if="update.type === 'payment'">
                          <div>
                            <label
                              for="price"
                              class="block text-sm font-medium text-gray-700"
                              >Price</label
                            >
                            <div class="mt-1 relative rounded-md shadow-sm">
                              <div
                                class="
                                  absolute
                                  inset-y-0
                                  left-0
                                  pl-3
                                  flex
                                  items-center
                                  pointer-events-none
                                "
                              >
                                <span class="text-gray-500 sm:text-sm">£</span>
                              </div>
                              <input
                                :required="update.type === 'payment'"
                                v-model="update.amount"
                                type="number"
                                step="any"
                                name="price"
                                inputmode="decimal"
                                id="price"
                                class="
                                  focus:ring-blue-500
                                  focus:border-blue-500
                                  block
                                  w-full
                                  pl-7
                                  pr-12
                                  sm:text-sm
                                  border-gray-300
                                  rounded-md
                                "
                                placeholder="0.00"
                              />
                              <div
                                class="
                                  absolute
                                  inset-y-0
                                  right-0
                                  flex
                                  items-center
                                "
                              >
                                <label for="currency" class="sr-only"
                                  >Currency</label
                                >
                                <select
                                  v-model="update.currency"
                                  id="currency"
                                  name="currency"
                                  class="
                                    focus:ring-blue-500
                                    focus:border-blue-500
                                    h-full
                                    py-0
                                    pl-2
                                    pr-7
                                    border-transparent
                                    bg-transparent
                                    text-gray-500
                                    sm:text-sm
                                    rounded-md
                                  "
                                >
                                  <option selected value="GBP">GBP</option>
                                  <option value="USD">USD</option>
                                  <option value="EUR">EUR</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div>
                            <label
                              for="payment-type"
                              class="
                                block
                                text-sm
                                font-medium
                                text-gray-700
                                sm:mt-px
                                sm:pb-2
                              "
                            >
                              Payment type
                            </label>
                            <div class="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                                v-model="update.payment_type"
                                id="payment-type"
                                name="payment-type"
                                class="
                                  max-w-lg
                                  block
                                  focus:ring-blue-500
                                  focus:border-blue-500
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  border-gray-300
                                  rounded-md
                                "
                              >
                                <option value="bank-transfer">
                                  Bank transfer
                                </option>
                                <option value="stripe">Stripe</option>
                              </select>
                            </div>
                          </div>
                          <div
                            v-if="
                              update.payment_type === 'stripe' &&
                              update.type === 'payment'
                            "
                          >
                            <label
                              for="payment-link"
                              class="block text-sm font-medium text-gray-700"
                            >
                              Payment Link
                            </label>
                            <div class="mt-1 flex rounded-md shadow-sm">
                              <span
                                class="
                                  inline-flex
                                  items-center
                                  px-3
                                  rounded-l-md
                                  border border-r-0 border-gray-300
                                  bg-gray-50
                                  text-gray-500
                                  sm:text-sm
                                "
                              >
                                http://
                              </span>
                              <input
                                :required="update.payment_type === 'stripe'"
                                v-model="update.payment_link"
                                type="text"
                                name="payment_link"
                                id="payment-link"
                                class="
                                  flex-1
                                  min-w-0
                                  block
                                  w-full
                                  px-3
                                  py-2
                                  rounded-none rounded-r-md
                                  focus:ring-blue-500
                                  focus:border-blue-500
                                  sm:text-sm
                                  border-gray-300
                                "
                                placeholder="pay.stripe.com/3948398e4"
                              />
                            </div>
                          </div>
                        </template>
                        <div
                          v-if="
                            update.template_id === 'new' &&
                            update.type === 'sign'
                          "
                        >
                          <label
                            for="template-upload"
                            class="
                              block
                              text-sm
                              font-medium
                              text-gray-700
                              sm:mt-px
                              sm:pb-2
                            "
                          >
                            Upload new template
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2 relative">
                            <div
                              v-if="isUploading"
                              class="
                                absolute
                                z-10
                                w-full
                                h-full
                                bg-gray-50
                                opacity-40
                                grid
                                place-content-center
                              "
                            >
                              <svg
                                class="animate-spin -ml-1 mr-3 h-5 w-5 z-10"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  class="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  class="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </div>
                            <div
                              class="
                                max-w-lg
                                flex
                                justify-center
                                px-6
                                pt-5
                                pb-6
                                border-2 border-gray-300 border-dashed
                                rounded-md
                              "
                            >
                              <div class="space-y-1 text-center">
                                <svg
                                  class="mx-auto"
                                  width="28"
                                  height="36"
                                  viewBox="0 0 28 36"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.49935 19.8333H19.4994M13.9994 14.3333L13.9994 25.3333M23.166 34.5H4.83268C2.80764 34.5 1.16602 32.8584 1.16602 30.8333V5.16667C1.16602 3.14162 2.80764 1.5 4.83268 1.5H15.0733C15.5595 1.5 16.0258 1.69315 16.3697 2.03697L26.2957 11.963C26.6395 12.3068 26.8327 12.7732 26.8327 13.2594V30.8333C26.8327 32.8584 25.1911 34.5 23.166 34.5Z"
                                    stroke="#9CA3AF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                <div
                                  class="flex text-sm text-gray-600 relative"
                                >
                                  <label
                                    for="template-upload"
                                    class="
                                      relative
                                      cursor-pointer
                                      bg-white
                                      rounded-md
                                      font-medium
                                      text-blue-600
                                      hover:text-blue-500
                                      focus-within:outline-none
                                      focus-within:ring-2
                                      focus-within:ring-offset-2
                                      focus-within:ring-blue-500
                                    "
                                  >
                                    <span>Select a template</span>
                                    <input
                                      :disabled="isUploading"
                                      @change="handleTemplateUpload"
                                      id="template-upload"
                                      name="template-upload"
                                      type="file"
                                      accept="application/pdf,image/png, image/jpeg"
                                      class="sr-only"
                                    />
                                    <input
                                      :required="update.template_id === 'new'"
                                      v-model="update.template_attachment.name"
                                      class="sr-only"
                                    />
                                  </label>
                                  <p class="pl-1">or drag and drop</p>
                                </div>
                                <p class="text-xs text-gray-500">
                                  PDF, PNG, JPEG up to 10MB
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span
                          v-if="update.template_attachment.name"
                          class="
                            inline-flex
                            rounded-full
                            items-center
                            py-0.5
                            pl-2.5
                            pr-1
                            text-sm
                            font-medium
                            bg-blue-100
                            text-blue-700
                          "
                        >
                          {{ update.template_attachment.name }}
                          <button
                            @click="update.template_attachment = {}"
                            type="button"
                            class="
                              flex-shrink-0
                              ml-0.5
                              h-4
                              w-4
                              rounded-full
                              inline-flex
                              items-center
                              justify-center
                              text-blue-400
                              hover:bg-blue-200
                              hover:text-blue-500
                              focus:outline-none
                              focus:bg-blue-500
                              focus:text-white
                            "
                          >
                            <span class="sr-only">Remove large option</span>
                            <svg
                              class="h-2 w-2"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 8 8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-width="1.5"
                                d="M1 1l6 6m0-6L1 7"
                              />
                            </svg>
                          </button>
                        </span>
                        <div
                          v-if="
                            !(
                              update.signature_type === 'digital-signature' &&
                              update.type === 'sign'
                            )
                          "
                        >
                          <label
                            for="file-upload"
                            class="
                              block
                              text-sm
                              font-medium
                              text-gray-700
                              sm:mt-px
                              sm:pb-2
                            "
                          >
                            {{
                              update.type === "payment" &&
                              update.payment_type === "bank-transfer"
                                ? "Invoice"
                                : "Attachment"
                            }}
                          </label>
                          <div class="mt-1 sm:mt-0 sm:col-span-2 relative">
                            <div
                              v-if="isUploading"
                              class="
                                absolute
                                z-10
                                w-full
                                h-full
                                bg-gray-50
                                opacity-40
                                grid
                                place-content-center
                              "
                            >
                              <svg
                                class="animate-spin -ml-1 mr-3 h-5 w-5 z-10"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <circle
                                  class="opacity-25"
                                  cx="12"
                                  cy="12"
                                  r="10"
                                  stroke="currentColor"
                                  stroke-width="4"
                                ></circle>
                                <path
                                  class="opacity-75"
                                  fill="currentColor"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                              </svg>
                            </div>
                            <div
                              class="
                                max-w-lg
                                flex
                                justify-center
                                px-6
                                pt-5
                                pb-6
                                border-2 border-gray-300 border-dashed
                                rounded-md
                              "
                            >
                              <div class="space-y-1 text-center">
                                <svg
                                  class="mx-auto"
                                  width="28"
                                  height="36"
                                  viewBox="0 0 28 36"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.49935 19.8333H19.4994M13.9994 14.3333L13.9994 25.3333M23.166 34.5H4.83268C2.80764 34.5 1.16602 32.8584 1.16602 30.8333V5.16667C1.16602 3.14162 2.80764 1.5 4.83268 1.5H15.0733C15.5595 1.5 16.0258 1.69315 16.3697 2.03697L26.2957 11.963C26.6395 12.3068 26.8327 12.7732 26.8327 13.2594V30.8333C26.8327 32.8584 25.1911 34.5 23.166 34.5Z"
                                    stroke="#9CA3AF"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                <div class="flex text-sm text-gray-600">
                                  <label
                                    for="file-upload"
                                    class="
                                      relative
                                      cursor-pointer
                                      bg-white
                                      rounded-md
                                      font-medium
                                      text-blue-600
                                      hover:text-blue-500
                                      focus-within:outline-none
                                      focus-within:ring-2
                                      focus-within:ring-offset-2
                                      focus-within:ring-blue-500
                                    "
                                  >
                                    <span>Select a file</span>
                                    <input
                                      :disabled="isUploading"
                                      @change="handleUpload"
                                      id="file-upload"
                                      name="file-upload"
                                      type="file"
                                      accept="application/pdf,image/png, image/jpeg"
                                      class="sr-only"
                                    />
                                    <input
                                      v-model="update.attachment.name"
                                      :required="
                                        (update.signature_type ===
                                          'wet-signature' &&
                                          update.type === 'sign') ||
                                        update.payment_type === 'bank-transfer'
                                      "
                                      class="sr-only"
                                    />
                                  </label>
                                  <p class="pl-1">or drag and drop</p>
                                </div>
                                <p class="text-xs text-gray-500">
                                  PDF, PNG, JPEG up to 10MB
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span
                          v-if="update.attachment.name"
                          class="
                            inline-flex
                            rounded-full
                            items-center
                            py-0.5
                            pl-2.5
                            pr-1
                            text-sm
                            font-medium
                            bg-blue-100
                            text-blue-700
                          "
                        >
                          {{ update.attachment.name }}
                          <button
                            @click="update.attachment = {}"
                            type="button"
                            class="
                              flex-shrink-0
                              ml-0.5
                              h-4
                              w-4
                              rounded-full
                              inline-flex
                              items-center
                              justify-center
                              text-blue-400
                              hover:bg-blue-200
                              hover:text-blue-500
                              focus:outline-none
                              focus:bg-blue-500
                              focus:text-white
                            "
                          >
                            <span class="sr-only">Remove large option</span>
                            <svg
                              class="h-2 w-2"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 8 8"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-width="1.5"
                                d="M1 1l6 6m0-6L1 7"
                              />
                            </svg>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                    "
                    @click="open = false"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-blue-600
                      hover:bg-blue-700
                      focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
                    "
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <div class="flex flex-col h-full">
    <div class="-my-2 sm:-mx-6 lg:-mx-8 h-full">
      <nav
        class="flex mb-4 justify-between sm:px-6 lg:px-8 lg:h-10 md:h-10"
        aria-label="Breadcrumb"
      >
        <ol class="flex items-center space-x-4">
          <li>
            <div>
              <a href="#" class="text-gray-400 hover:text-gray-500">
                <HomeIcon class="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span class="sr-only">Home</span>
              </a>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <router-link
                :to="{ name: 'staff-cases' }"
                class="
                  ml-4
                  text-sm
                  font-medium
                  text-gray-500
                  hover:text-gray-700
                "
                >Cases
              </router-link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <ChevronRightIcon
                class="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <router-link
                :to="{ name: 'staff-case', params: { id: cases.id } }"
                class="
                  ml-4
                  text-sm
                  font-medium
                  text-gray-500
                  hover:text-gray-700
                "
                >{{ cases.id }}
              </router-link>
            </div>
          </li>
        </ol>
      </nav>
      <div v-if="cases.id" class="align-middle inline-block min-w-full h-full">
        <h3
          class="
            text-3xl
            font-bold
            leading-6
            mb-4
            text-gray-900
            sm:px-6
            lg:px-8
          "
        >
          {{ cases.info.app_type.name }}
        </h3>
        <div class="border-b border-gray-200 sm:pl-6 lg:pl-8">
          <nav class="-mb-px flex" aria-label="Tabs">
            <router-link
              v-for="tab in tabs"
              :key="tab.name"
              :to="{ name: tab.to }"
              active-class="border-blue-500 text-blue-600"
              class="
                whitespace-nowrap
                flex
                py-4
                px-1
                border-b-2
                mr-8
                font-medium
                text-sm
                border-transparent
                text-gray-500
              "
            >
              {{ tab.name }}
              <span
                v-if="tab.count > 0"
                class="
                  hidden
                  ml-3
                  py-0.5
                  px-2.5
                  rounded-full
                  text-xs
                  font-medium
                  md:inline-block
                  bg-blue-100
                  text-blue-600
                "
                >{{ tab.count }}</span
              >
            </router-link>
            <span
              class="
                ml-auto
                relative
                z-0
                inline-flex
                shadow-sm
                rounded-md
                self-center
              "
            >
              <button
                @click="open = true"
                type="button"
                class="
                  relative
                  inline-flex
                  items-center
                  px-4
                  py-2
                  rounded-l-md
                  border border-blue-300
                  bg-white
                  text-sm
                  font-medium
                  text-gray-700
                  hover:bg-blue-50
                  focus:z-10
                  focus:outline-none
                  focus:ring-1 focus:ring-blue-500
                  focus:border-blue-500
                "
              >
                <DocumentAddIcon
                  class="flex-shrink-0 h-5 w-5 text-blue-500 mr-3"
                />
                Send update
              </button>
              <Menu as="div" class="relative inline-block text-left">
                <MenuButton>
                  <button
                    type="button"
                    class="
                      -ml-px
                      relative
                      inline-flex
                      items-center
                      px-4
                      py-2
                      rounded-r-md
                      border border-blue-300
                      bg-white
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-blue-50
                      focus:z-10
                      focus:outline-none
                      focus:ring-1 focus:ring-blue-500
                      focus:border-blue-500
                    "
                  >
                    <DotsVerticalIcon
                      class="h-5 w-5 text-blue-500"
                      aria-hidden="true"
                    />
                  </button>
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="
                      origin-top-right
                      absolute
                      right-0
                      mt-2
                      w-56
                      rounded-md
                      shadow-lg
                      bg-white
                      ring-1 ring-black ring-opacity-5
                      divide-y divide-gray-100
                      focus:outline-none
                    "
                  >
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a
                          href="#"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                          ]"
                        >
                          <PencilAltIcon
                            class="
                              mr-3
                              h-5
                              w-5
                              text-gray-400
                              group-hover:text-gray-500
                            "
                            aria-hidden="true"
                          />
                          Edit
                        </a>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <a
                          href="#"
                          :class="[
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'group flex items-center px-4 py-2 text-sm',
                          ]"
                        >
                          <ArchiveIcon
                            class="
                              mr-3
                              h-5
                              w-5
                              text-gray-400
                              group-hover:text-gray-500
                            "
                            aria-hidden="true"
                          />
                          Archive
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </span>
          </nav>
        </div>
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ChevronRightIcon,
  HomeIcon,
  DocumentAddIcon,
  DotsVerticalIcon,
  PencilAltIcon,
  ArchiveIcon,
  XIcon,
} from "@heroicons/vue/solid";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  RadioGroup,
  RadioGroupDescription,
  RadioGroupLabel,
  RadioGroupOption,
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import HelloSign from "hellosign-embedded";

import { useStore } from "vuex";

import { getTemplates, createClaimEmbeded } from "@/services/sign";

import { useToast } from "vue-toastification";

import { upload, createUpdate } from "@/services/action";

import { ref, computed, watch, onMounted } from "vue";

import { useRoute } from "vue-router";

const pages = [
  { name: "Cases", route: "staff-cases", current: false },
  { name: "EXS-00216120/999/Misc", route: "case", id: 1, current: true },
];
const tabs = [
  { name: "Details", to: "staff-case-details", count: "0", current: true },
  { name: "Updates", to: "staff-case-updates", count: "6", current: false },
];
export default {
  components: {
    ChevronRightIcon,
    HomeIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DocumentAddIcon,
    DotsVerticalIcon,
    PencilAltIcon,
    ArchiveIcon,
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  setup() {
    const client = new HelloSign({
      clientId: "f078869176519d71899ea696680b7c06",
    });
    const toast = useToast();
    const store = useStore();
    const route = useRoute();
    const open = ref(false);
    const isUploading = ref(false);
    const templates = ref([]);
    const update = ref({
      type: "information",
      attachment: { name: "" },
      template_attachment: { name: "" },
      currency: "GBP",
      template_id: "",
      template_name: "",
    });

    onMounted(() => {
      store.dispatch("cases/getCase", route.params.id);
    });

    watch(
      () => update.value.signature_type,
      async (val) => {
        if (templates.value.length > 0) return;
        if (val === "digital-signature") {
          const { data } = await getTemplates();
          templates.value = data;
        }
      }
    );

    const handleTemplateChange = (e) => {
      const val = e.target.value;
      if (val === "new") return;
      update.value.template_name =
        e.target.options[e.target.selectedIndex].text;
    };

    const handleTemplateUpload = async (event) => {
      try {
        isUploading.value = true;
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("files", file);
        const { data } = await upload(formData);
        update.value.template_attachment = data[0];
        event.target.value = "";
        toast.success("File uploaded successfully!");
        isUploading.value = false;
        await prepareClaimEmbedded(data[0].url);
      } catch (e) {
        console.log(e);
        isUploading.value = false;
        update.value.template_attachment = {};
      }
    };

    const handleUpload = async (event) => {
      try {
        isUploading.value = true;
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("files", file);
        const { data } = await upload(formData);
        update.value.attachment = data[0];
        event.target.value = "";
        toast.success("File uploaded successfully!");
        isUploading.value = false;
      } catch (e) {
        isUploading.value = false;
        update.value.attachment = {};
      }
    };

    const populateValue = (type, value) => {
      return update.value.type === type ? value : undefined;
    };

    const prepareClaimEmbedded = async (url) => {
      const {
        data: {
          template: { edit_url },
        },
      } = await createClaimEmbeded(url);

      client.off("createTemplate");

      client.open(edit_url, {
        skipDomainVerification: true,
        testMode: true,
      });

      client.on(
        "createTemplate",
        (data) => handleAfterCreatedTemplate(data),
        false
      );
    };

    const handleAfterCreatedTemplate = (data) => {
      toast.success("Template created successfully!");
      setTimeout(() => {
        toast.warning("Don't forget to send the action");
      }, 1000);
      templates.value.push({
        title: data.templateInfo.title,
        template_id: data.templateId,
      });
      update.value.template_name = data.templateInfo.title;
      update.value.template_id = data.templateId;
    };

    const handleSubmit = async () => {
      try {
        await createUpdate({
          update_type: update.value.type,
          signature_type: populateValue("sign", update.value.signature_type),
          description: update.value.description,
          template_id: update.value.template_id,
          template_name: update.value.template_name,
          update_status: 1,
          amount: populateValue("payment", update.value.amount),
          payment_source: populateValue("payment", update.value.payment_type),
          payment_link: populateValue("payment", update.value.payment_link),
          currency: populateValue("payment", update.value.currency),
          attachment: update.value.attachment.id,
          case: route.params.id,
        });
        open.value = false;
        toast.success("Update sent successfully!");
      } catch (e) {
        console.log(e);
      }
    };

    store.dispatch("action/getUpdateTypes");
    store.dispatch("action/getSignatureTypes");
    return {
      tabs,
      pages,
      update,
      open,
      isUploading,
      handleUpload,
      handleSubmit,
      handleTemplateChange,
      templates,
      prepareClaimEmbedded,
      handleTemplateUpload,
      update_types: computed(() => store.state.action.update_types),
      signature_types: computed(() => store.state.action.signature_types),
      cases: computed(() => store.state.cases.case),
    };
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
</style>