import axios from './http';

export const getEmbeddedSign = (template_id) =>
  axios.get(`sign/signatureRequest/${template_id}`);

export const createClaimEmbeded = (path) =>
  axios.post(`sign/claimEmbeded`, {
    path,
  });

export const getTemplates = () => axios.get(`sign/templates`);
